@import 'styles/mixins/fonts-2024';
@import 'styles/layout-tokens';
@import 'styles/colors-2024';

$button-br: 0.5rem;
$button-transition: all 0.2s ease-out;
.base {
	display: inline-grid;
	grid-template: 'container';
	place-items: center;

	background: none;
	border: none;
	cursor: pointer;
	text-align: center;

	position: relative;

	font: inherit;

	color: inherit;

	padding: 0;

	transition: all 0.2s ease-out;

	&:disabled {
		cursor: auto;
	}

	> * {
		grid-area: container;
	}

	--shadow-color: 0deg 0% 75%;
	--shadow-elevation-low: 0px 1px 1.1px hsl(var(--shadow-color) / 0.22), 0px 1.5px 1.7px -1.2px hsl(var(--shadow-color) / 0.22), 0px 3.3px 3.7px -2.5px hsl(var(--shadow-color) / 0.22);
	--shadow-elevation-medium: 0px 1px 1.1px hsl(var(--shadow-color) / 0.23), 0px 2.9px 3.3px -0.8px hsl(var(--shadow-color) / 0.23), 0px 6.9px 7.8px -1.7px hsl(var(--shadow-color) / 0.23),
		-0.1px 16.5px 18.6px -2.5px hsl(var(--shadow-color) / 0.23);
	--shadow-elevation-high: 0px 1px 1.1px hsl(var(--shadow-color) / 0.21), 0px 4.1px 4.6px -0.4px hsl(var(--shadow-color) / 0.21), 0px 7.4px 8.3px -0.7px hsl(var(--shadow-color) / 0.21),
		0px 11.9px 13.4px -1.1px hsl(var(--shadow-color) / 0.21), -0.1px 18.8px 21.2px -1.4px hsl(var(--shadow-color) / 0.21), -0.1px 29.2px 32.9px -1.8px hsl(var(--shadow-color) / 0.21),
		-0.2px 44.2px 49.7px -2.1px hsl(var(--shadow-color) / 0.21), -0.2px 65px 73.1px -2.5px hsl(var(--shadow-color) / 0.21);
}

@mixin round_button {
	padding: 1rem 2rem; //TODO: tokenize
	gap: 0.875rem; //TODO: tokenize
	border-radius: $button-br; //TODO: tokenize
}

.button_span {
	@include button1;
	display: flex;
	align-items: center;
	justify-content: center;
}

$core-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.25) inset, 0px -4px 10px 0px rgba(255, 255, 255, 0.25) inset;
$disabled: #d0d5dd; //TODO: tokenize
$white-border: white 0 0 0 1px inset;

.primary {
	@include round_button;

	background: rgba(255, 255, 255, 0.2);
	color: $white;

	backdrop-filter: blur(12px);

	&:hover,
	&:focus-visible {
		background: rgba(255, 255, 255, 0.25);
		box-shadow: $core-shadow;
	}

	&:active {
		background: rgba(255, 255, 255, 0.3);
		box-shadow: $core-shadow, $white-border;
	}

	&:disabled {
		background: $disabled;
	}
}

.white {
	@include round_button;

	background: none;
	color: $black;

	isolation: isolate;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		background: $white;
		border-radius: $button-br;
		z-index: -1;

		transition: $button-transition;
	}

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(180deg, #ffffff 0%, #9e9e9e 100%);
		border-radius: $button-br;
		z-index: -2;
		opacity: 0;

		transition: $button-transition;
	}

	backdrop-filter: blur(12px);

	&:hover,
	&:focus-visible {
		&::before {
			box-shadow: $core-shadow;
			background: rgba(255, 255, 255, 0.7);
		}
	}

	&:active {
		&::before {
			scale: 0.99 0.97;
			box-shadow: none;
			background: $white;
		}
		&::after {
			opacity: 1;
		}
	}

	&:disabled {
		&::before {
			background: $disabled;
		}

		&::after {
			opacity: 0;
		}

		color: white;
	}
}

.blue {
	@include round_button;

	background: none;
	color: $white;

	isolation: isolate;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		background: #024cdf;
		border-radius: $button-br;
		z-index: -1;

		transition: $button-transition;
	}

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(180deg, #4f8bff 0%, #003095 100%);
		border-radius: $button-br;
		z-index: -2;
		opacity: 0;

		transition: $button-transition;
	}

	&:hover,
	&:focus-visible {
		&::before {
			box-shadow: 0px -4px 9px 0px #003092 inset, 0px 6px 14px 0px rgba(255, 255, 255, 0.25) inset;
			background: #00369f;
		}
	}

	&:active {
		&::before {
			scale: 0.99 0.97;
			box-shadow: none;
			background: #1862ff;
		}

		&::after {
			opacity: 1;
		}
	}
}

.dark {
	@include round_button;

	background: none;
	color: $white;

	isolation: isolate;

	&::before {
		content: '';
		position: absolute;
		inset: 0;
		background: $black;
		border-radius: $button-br;
		z-index: -1;

		transition: $button-transition;
	}

	&::after {
		content: '';
		position: absolute;
		inset: 0;
		background: linear-gradient(180deg, #ffffff 0%, rgba(0, 47, 148, 0.25) 100%);
		border-radius: $button-br;
		z-index: -2;
		opacity: 0;

		transition: $button-transition;
	}

	&:hover,
	&:focus-visible {
		&::before {
			box-shadow: 0px -4px 9px 0px #4e4e4e inset, 0px 6px 14px 0px rgba(255, 255, 255, 0.25) inset;
			background: #171920;
		}
	}

	&:active {
		&::before {
			scale: 0.99 0.97;
			box-shadow: none;
			background: #171920;
		}

		&::after {
			opacity: 1;
		}
	}

	&:disabled {
		&::before {
			background: $disabled;
		}

		&::after {
			opacity: 0;
		}
	}
}

$shadow-color: 0 0 0.63;

.plus {
	@include round_button;

	background: #f5f5f5; //TODO: tokenize
	color: $black;

	padding: 1rem !important;

	span {
		@include button1;
	}

	&:hover,
	&:focus-visible {
		scale: 1.01;
		box-shadow: var(--shadow-elevation-medium);
	}

	&:active {
		scale: 0.99;
		background: darken(#f5f5f5, 5%);
		box-shadow: hsl(var(--shadow-color) / 0.22) 0 0 10px inset, var(--shadow-elevation-low);
	}

	&:disabled {
		background: $disabled;
	}

	svg {
		margin-left: 0.5rem;
	}
}

.plus_small {
	@include round_button;

	padding: 0.75rem 1.5rem;

	background: #f5f5f5; //TODO: tokenize
	color: $black;

	&:hover,
	&:focus-visible {
		scale: 1.01;
		box-shadow: var(--shadow-elevation-medium);
	}

	&:active {
		scale: 0.99;
		background: darken(#f5f5f5, 5%);
		box-shadow: hsl(var(--shadow-color) / 0.22) 0 0 10px inset, var(--shadow-elevation-low);
	}

	&:disabled {
		background: $disabled;
	}

	svg {
		margin-left: 0.5rem;
	}

	span {
		@include headline10;
	}
}

.text_link {
	color: #1862ff;

	span {
		font-size: 1rem;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: 0.14rem;
		text-transform: uppercase;
	}

	&:disabled {
		opacity: 0.4;
	}

	svg {
		margin-left: 0.2em;
		height: 1em;
		width: 1em;
		transform: translateY(-0.05em);
	}
}

.subtle_link {
	color: #a0a0a0;

	&:hover,
	&:focus-visible {
		text-decoration: underline;
	}

	&:active,
	&:disabled {
		text-decoration: none;
	}

	svg {
		margin-left: 0.3em;
		height: 1.3em;
		transform: translateY(-0.05em);
	}
}

.autopilot {
	@include round_button;
	box-shadow: inset rgba(255, 255, 255, 0.54) 0 0 0 1px;
	color: #ffffffe5;
	background: #c41fcc;

	overflow: hidden;

	isolation: isolate;

	canvas {
		position: absolute;

		inset: 0;

		z-index: -1;

		border-radius: $button-br;
	}

	span {
		gap: 0.375rem;
	}
}

/**
 * Spinner
 */

.loading_spinner {
	height: 24px;
	width: 24px;
	position: absolute;

	svg {
		path {
			opacity: 0;
			animation: lds-spinner 1s ease infinite;
		}
	}
}

@keyframes lds-spinner {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
